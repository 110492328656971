/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"

import Header from "./header"
import Nav from "./nav"
import "./layout.css"
import Footer from "./footer"
import ClientOnly from "./client-only"
import Lines from "../components/lines"

const duration = 1
const ease = [0.6, -0.05, 0.01, 0.9]

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    ease,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    ease,
    transition: { duration: duration },
  },
}

// Navigation
const itemsA = ["Work", "Services"]
const itemsB = ["LinkedIn", "Instagram", "Behance"]
const urlA = ["/", "/services"]
const urlB = [
  "https://www.linkedin.com/in/diegooriani/",
  "https://www.instagram.com/doplaymore/",
  "https://www.behance.net/diegooriani",
]

const Layout = ({ children, location }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  const [menuState, setMenuState] = useState(false)

  if (typeof window !== undefined) {
    return (
      <div style={{ margin: `0 auto`, maxWidth: "3840px" }}>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <ClientOnly>
              <Header
                menuState={menuState}
                setMenuState={setMenuState}
                itemsA={itemsA}
                urlA={urlA}
                location={location.pathname}
              />
              <Nav
                menuState={menuState}
                setMenuState={setMenuState}
                itemsA={["Work", "Services"]}
                urlA={["/", "/services"]}
              />
            </ClientOnly>
            <Lines />
            <AnimatePresence>
              <motion.main
                key={location.pathname}
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                <main>{children}</main>
                <Footer
                  text=""
                  titleA="Sections"
                  titleB="Elsewhere"
                  itemsA={itemsA}
                  itemsB={itemsB}
                  urlA={urlA}
                  urlB={urlB}
                />
              </motion.main>
            </AnimatePresence>
          </div>
        </div>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  location: {},
}

export default React.memo(Layout)
