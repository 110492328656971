import React from "react"

const Lines = () => {
  return (
    <div className="grid gap-3 grid-cols-12">
      <div className="col-start-2 col-span-1">
        <div className="line" />
      </div>
      <div className="hidden xl:grid col-start-5 col-span-1">
        <div className="line" />
      </div>
      <div className="col-start-12 col-span-1">
        <div className="line" />
      </div>
    </div>
  )
}

export default React.memo(Lines)
