import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ text, titleA, titleB, itemsA, itemsB, urlA, urlB }) => {
  const _renderItemsA = itemsA.map((item, index) => (
    <li key={index} className="text-p sm:text-h5">
      <Link
        to={urlA[index]}
        style={{
          boxShadow: "0 -1px 0 0 rgba(255, 255, 255, 0) inset",
        }}
        activeStyle={{
          cursor: "default",
          boxShadow: "0 -1px 0 0 rgba(255, 255, 255, 0.2) inset",
          opacity: 0.5,
        }}
      >
        {item}
      </Link>
    </li>
  ))

  const _renderItemsB = itemsB.map((item, index) => (
    <li key={index} className="text-p sm:text-h5 pb-3">
      <a href={urlB[index]} target="_blank" rel="noreferrer">
        {item}
      </a>
    </li>
  ))

  return (
    <footer className="bg-black pt-32 pb-16">
      <div className="grid grid-cols-12 gap-8">
        <div className="col-start-2 col-span-10 md:col-start-2 md:col-span-3 xl:col-start-2 xl:col-span-3">
          <ul className="grid grid-cols-1 list">
            <span className="text-p xl:text-h5 lg:text-p md:text-p pb-2 xs:pb-2 md:pb-5 opacity-50">
              {/* <a href="https://www.diegooriani.com">diegooriani.com</a> */}
              <div>diegooriani.com</div>
            </span>
            <li className="text-h5 sm:text-h5 md:text-h5 xl:text-h5">
              © {new Date().getFullYear()} {text}
            </li>
          </ul>
        </div>
        <div className="col-start-2 col-span-10 md:col-start-6 md:col-span-3 xl:col-start-5 xl:col-span-7">
          <ul className="grid grid-cols-1 list">
            <span className="text-p xl:text-h5 lg:text-p md:text-p pb-2 xs:pb-2 md:pb-5 opacity-50">
              {titleB}
            </span>
            {_renderItemsB}
          </ul>
        </div>
        {/* <div className="col-start-2 col-span-10 md:col-start-9 md:col-span-3 xl:col-start-10 xl:col-span-3">
          <ul className="grid grid-cols-1 list">
            <span className="text-p xl:text-h5 lg:text-p md:text-p pb-2 xs:pb-2 md:pb-5 opacity-50">
              {titleB}
            </span>
            {_renderItemsB}
          </ul>
        </div> */}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  titleA: PropTypes.string,
  itemsA: PropTypes.array,
  titleB: PropTypes.string,
  itemsB: PropTypes.array,
}

Footer.defaultProps = {
  titleA: `Title here`,
  itemsA: ["1", "2", "3", "4"],
  titleB: `Title here`,
  itemsB: ["1", "2", "3", "4"],
}

export default React.memo(Footer)
