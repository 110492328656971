import { Link } from "gatsby"
import React, { useRef } from "react"
import { motion, AnimatePresence } from "framer-motion"

// Animation setup
const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] }

const parent = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  hidden: {
    transition: {
      when: "afterChildren",
      staggerChildren: 0,
    },
  },
}

const itemSlideUp = {
  visible: { y: 0 },
  hidden: { y: 200 },
}

const Nav = ({ menuState, setMenuState, itemsA, urlA }) => {
  const refNav = useRef(null)

  return (
    <AnimatePresence>
      {menuState && (
        <motion.div className="h-48">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: window.innerWidth }}
            exit={{ width: 0, right: [null, 0, 0] }}
            transition={transition}
            className="nav-panel"
          ></motion.div>
          <nav
            ref={refNav}
            className="grid grid-cols-1 xl:grid-cols-12 place-content-center"
          >
            <div className="xl:col-start-2 xl:col-span-10 text-center xl:text-left">
              <motion.ul
                variants={parent}
                initial="hidden"
                animate="visible"
                exit="hidden"
                transition={transition}
                className="navList grid grid-flow-col grid-cols-1 grid-rows-4 xl:grid-cols-2 xl:grid-rows-3"
              >
                {itemsA.map((item, i) => (
                  <li key={i.toString()} className="listMenu overflow-hidden">
                    <Link to={urlA[i]} onClick={() => setMenuState(!menuState)}>
                      <motion.div
                        transition={transition}
                        variants={itemSlideUp}
                        className="text-h4 sm:text-h3 md:text-h2 xl:text-h1 text-white hover:underline hover:opacity-50 whitespace-nowrap"
                      >
                        {item}
                      </motion.div>
                    </Link>
                  </li>
                ))}
              </motion.ul>
            </div>
          </nav>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default React.memo(Nav)
